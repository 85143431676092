import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/SEO/index"
import Header from "../components/Header"
import Footer from "../components/Footer"
import i18n from "../i18n/i18n"
import Hero from "../components/ContentTypes/Hero"

import { withTranslation } from "react-i18next"


const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en"
  }

  const lang = navigator && navigator.language && navigator.language.split("-")[0]
  if (!lang) return "en"

  switch (lang) {
    case "de":
      return "de"
    default:
      return "en"
  }
}

i18n.changeLanguage(getRedirectLanguage())

function PageNotFound(props) {
  const language = getRedirectLanguage()

  const { settings } = useStaticQuery(graphql`
        query SettingsNotFound {
          settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
            edges {
              node {
                name
                full_slug
                content
              }
            }
          }
        } 
      `)

      let correctSetting = settings.edges.filter(edge => edge.node.full_slug.indexOf(language) > -1)
      let hasSetting = correctSetting && correctSetting.length ? correctSetting[0].node : {}
      let content = typeof hasSetting.content === "string" ? JSON.parse(hasSetting.content) : hasSetting.content
      let parsedSetting = Object.assign({}, content, {content: content})

      let homeUrl = "/en"

      if (language === "de") {
        homeUrl = "/de"
      } else if (language === "en") {
        homeUrl = "/en"
      }

      const heroContent = {
        headline: `${ props.t("notFound.headline") }.`,
        teaser: props.t("notFound.teaser"),
        image: {
          filename: "https://s.condu.co/f/98089/x/b9026d34f6/404.svg",
          alt: props.t("notFound.headline")
        },
        buttons: [{
          type: "primary",
          url: homeUrl,
          label: props.t("notFound.buttonLabel")
        }]
      }

      return (
        <div>
          <SEO title={props.t("notFound.headline")} lang={language} />
          <Header settings={parsedSetting} lang={language} location={{pathname: ""}} />
          <main>
            <Hero blok={heroContent} />
          </main>
          <Footer settings={parsedSetting} />
        </div>
      )
}

export default withTranslation()(PageNotFound)